<template>
  <div class="releaseVideo">
    <el-tabs v-model="activeName">
      <el-tab-pane label="发布视频" name="1">
        <div class="release_content">
          <el-form :model="listQuery" :rules="rules" ref="ruleForm" label-width="60px" class="ruleForm">
            <el-form-item label="主题" prop="name">
              <el-input @input="onInput" :maxlength="15" v-model="listQuery.videoTitle" placeholder="请输入主题(15个字以内)"></el-input>
            </el-form-item>
            <el-form-item label="简介">
              <el-input type="textarea" v-model="listQuery.videoDesc" maxlength="60" placeholder="请输入简介(40个字以内)"></el-input>
            </el-form-item>
            <el-form-item label="分类">

              <button v-for="(item,index) in videoClassList" :key="index" class="videoType"
                @click="videoTypeFn(item.classID)" :class="listQuery.classID==item.classID?'activeVideoType':''"
                type="button">{{item.className}} </button>
            </el-form-item>
            <el-form-item label="视频">
              <el-upload v-if="uploadInfo" class="upload-demo" drag action multiple accept=".mp4"
                :on-preview="handlepreview" :show-file-list="false" :http-request="httprequest">
                <i class="iconfont icon-shangchuan"></i>
                <div class="el-upload__text">拖拽视频到此处也可上传<button class="realseBtn"
                    type="button">{{bthText}}</button><span>上传完成后，请点击立即发布，上传后的视频需要审核，请耐心等待</span></div>
              </el-upload>
              <div v-else class="progress_box">
                <p v-if="uploadSucessInfo" class="uploadSuccess_text">上传成功</p>
                <p v-else class="pro_text">{{progressNum}}%<br>loading...</p>

                <el-progress :percentage="progressNum"></el-progress>
                <span>上传完成后，请点击立即发布，上传后的视频需要审核，请耐心等待</span>
              </div>
            </el-form-item>
            <el-form-item label="">
              <button class="submitRelase" type="button" @click="publishVideo">立即发布</button>
             <!-- <button class="submitRelase" type="button" @click="cancelUpload">取消上传</button> -->
            </el-form-item>
            <el-form-item label="" class="tipText">
              <!-- <p>温馨提示</p>
              <p>1、上传的视频不得大于XXXKB</p>
              <p>2、视频内容要健康，不得违反《xxx规定》</p>
              <p>3、视频一旦上传成功，将默认授权联众公司在推广宣传中使用视频。</p> -->

            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <p style="width:750px;margin:0px auto;padding-left:120px;color:gray;line-height:26px;">温馨提示：</br>
1、每个用户每天限上传2个视频，且单个视频大小不得超过50M，请谨慎上传</br>
2、视频内容要健康，不得违反《创作公约》</br>
3、视频一旦上传成功，将默认授权联众公司在推广宣传中使用视频</p>
    </el-tabs>
  </div>
</template>

<script>
  import {
    createUploadVideo,
    refreshUploadVideo,
    getVideosecondyClass,
    publish,
    getVideoPlayAuth,
    editVideo,
    getVideoDetailByVideoID
  } from '../api/authority'
  export default {
    name: "releaseVideo",
    data() {
      return {
        activeName: '1',
        rules: {
          videoTitle: [{
            required: true,
            message: '请输入主题',
            trigger: 'blur'
          }, ],
        },
        bthText:'上传视频',
        listQuery: {
          aliVideoId: '',
          classID: '',
          coverUrl: '',
          duration: '',
          videoDesc: '',
          videoID: '',
          videoTitle: '',
        },
        videoClassList: [],
        uploadInfo: true,
        uploadSucessInfo: false,
        progressNum: 0,
        videoType: ['升级', '军旗', '大砸会'],
        activeVideoType: 0,
        name: '',
        uploader: ''
      };
    },
    mounted() {
      this.getClassList()
      if (this.$route.query.videoId) {
        this.bthText = '请重新上传视频  '
        this.getVideoDetailByVideoIDFn()
      }
    },
    methods: {
      onInput() {
        this.$forceUpdate();
      },
      videoTypeFn(id) {
        this.listQuery.classID = id
      },
      // 获取file
      handlePreview(file) {

        console.log(file)
        debugger
        var userData = '{"Vod":{}}'
        this.uploader = this.createUploader()
        this.uploader.addFile(file, null, null, null, userData)
      },
      sizeTostr(size) {
      var data = "";

      data = (size / (1024 * 1024)).toFixed(2) 

      var sizestr = data + "";
      var len = sizestr.indexOf("\.");
      var dec = sizestr.substr(len + 1, 2);
      if (dec == "00") {//当小数点后为00时 去掉小数部分  
          return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
      }
      return sizestr;
  } , 
      // 覆盖http请求
      httprequest(file) {
        if (this.listQuery.videoTitle != '') {
          var userData = '{"Vod":{}}'
         if(this.$store.state.userName == '*bbsmaster' && this.sizeTostr(file.file.size)>500){
            this.$message({
             'message': '上传视频最大为500MB请压缩视频重新上传'
            })
            return false
         }
          if(this.$store.state.userName != '*bbsmaster' && this.sizeTostr(file.file.size)>50){
            this.$message({
             'message': '上传视频最大为50MB请压缩视频重新上传'
            })
            return false
          }
          this.uploader = this.createUploader()
          this.uploader.addFile(file.file, null, null, null, userData)
          this.uploader.startUpload()
        } else {
          this.$message({
            'message': '请输入主题'
          })
        }
      },
      // 视频详情
      getVideoDetailByVideoIDFn() {
        getVideoDetailByVideoID({
          videoID: this.$route.query.videoId
        }).then(response => {
          if (response.data.code == 0) {
            [this.listQuery] = [response.data.data]
            // this.listQuery.classID = response.data.data.classID
            console.log(this.listQuery)
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 编辑视频
      editVideoFn() {
        editVideo(this.listQuery).then(response => {
          if (response.data.code == 0) {
            this.$router.push({
              path: '/secondary/videoAdministration',
              query: {}
            })
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 保存视频
      publishVideo() {
        if (this.$route.query.videoId) {
          this.editVideoFn()
          return false
        }
        if (this.uploadSucessInfo) {
          publish(this.listQuery).then(response => {
            if (response.data.code == 0) {
              getVideoPlayAuth({
                videoId: this.listQuery.aliVideoId
              }).then(response => {
                // if (response.data.code == 0) {
                  this.$router.push({
                    path: '/secondary/videoAdministration',
                    query: {}
                  })
                // } else {
                //   this.$message({
                //     'message': response.data.msg
                //   })
                // }
              })

            } else {
                 this.$router.push({
                    path: '/secondary/videoAdministration',
                    query: {}
                  })
              // this.$message({
              //   'message': response.data.msg
              // })
            }
          })
        } else {
          this.$message({
            'message': '请上传视频'
          })
        }
      },
      // 取消视频上传
      // cancelUpload(){
      //   if(this.uploader){
      //     this.uploader.stopUpload();
      //     this.uploadInfo = true
      //   }
          
      // },
      // 获取分类列表
      getClassList() {
        getVideosecondyClass().then(response => {
          if (response.data.code == 0) {
            this.videoClassList = response.data.data
            if (typeof this.$route.query.videoId == 'undefined') {
              this.listQuery.classID = this.videoClassList[0].classID
            }

          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 刷新上传凭证
      refreshVoucher(uploadInfo) {
        refreshUploadVideo({
          videoId: uploadInfo.videoId
        }).then(response => {
          if (response.data.code == 0) {
            var data = response.data.data
            this.listQuery.aliVideoId = data.aliVideoId
            this.uploader.setUploadAuthAndAddress(uploadInfo, data.uploadAuth, data.uploadAddress, data.aliVideoId)
            this.uploadInfo = false
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 获取上传凭证
      getVoucher(uploadInfo) {
        createUploadVideo({
          filename: encodeURI(uploadInfo.file.name) ,
          title: this.listQuery.videoTitle,
          filesize:2
        }).then(response => {
          if (response.data.code == 0) {
            var data = response.data.data
            this.listQuery.aliVideoId = data.aliVideoId
            this.uploader.setUploadAuthAndAddress(uploadInfo, data.uploadAuth, data.uploadAddress, data.aliVideoId)
            this.uploadInfo = false
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 阿里上传视频
      createUploader(type) {
        let self = this
        let uploader = new AliyunUpload.Vod({
          timeout: self.timeout || 60000,
          partSize: self.partSize || 1048576,
          parallel: self.parallel || 5,
          retryCount: self.retryCount || 3,
          retryDuration: self.retryDuration || 2,
          region: self.region,
          userId: self.userId,
          // 添加文件成功
          addFileSuccess: function (uploadInfo) {
            console.log("addFileSuccess: " + uploadInfo.file.name)
          },
          // 开始上传
          onUploadstarted: function (uploadInfo) {
            // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
            // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
            // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
            // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
            // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
            // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
            // debugger
            if (!uploadInfo.videoId) {

              self.getVoucher(uploadInfo)
              // let createUrl = 'https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/CreateUploadVideo?Title=testvod1&FileName=aa.mp4&BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&VideoId=5bfcc7864fc14b96972842172207c9e6'
              // axios.get(createUrl).then(({data}) => {
              //   let uploadAuth = data.UploadAuth
              //   let uploadAddress = data.UploadAddress
              //   let videoId = data.VideoId
              //   uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)                
              // })
              // self.statusText = '文件开始上传...'
              console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint +
                ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
            } else {
              // debugger
              self.refreshVoucher(uploadInfo)
              // 如果videoId有值，根据videoId刷新上传凭证
              // https://help.aliyun.com/document_detail/55408.html?spm=a2c4g.11186623.6.630.BoYYcY
              // let refreshUrl = 'https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/RefreshUploadVideo?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&Title=haha1&FileName=xxx.mp4&VideoId=' + uploadInfo.videoId
              // axios.get(refreshUrl).then(({data}) => {
              //   let uploadAuth = data.UploadAuth
              //   let uploadAddress = data.UploadAddress
              //   let videoId = data.VideoId
              //   uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
              // })

            }
          },
          // 文件上传成功
          onUploadSucceed: function (uploadInfo) {
            console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint +
              ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
            self.uploadSucessInfo = true

            self.statusText = '文件上传成功!'
          },
          // 文件上传失败
          onUploadFailed: function (uploadInfo, code, message) {
            console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
            self.statusText = '文件上传失败!'
          },
          // 取消文件上传
          onUploadCanceled: function (uploadInfo, code, message) {
            console.log("Canceled file: " + uploadInfo.file.name + ", code: " + code + ", message:" + message)
            self.statusText = '文件已暂停上传'
          },
          // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
          onUploadProgress: function (uploadInfo, totalSize, progress) {
            console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize +
              ", percent:" + Math.ceil(progress * 100) + "%")
            let progressPercent = Math.ceil(progress * 100)
            self.progressNum = progressPercent
            // self.statusText = '文件上传中...'
          },
          // 上传凭证超时
          onUploadTokenExpired: function (uploadInfo) {
            // debugger
            self.refreshVoucher(uploadInfo)
            // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
            // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
            // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
            // let refreshUrl = 'https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/RefreshUploadVideo?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&Title=haha1&FileName=xxx.mp4&VideoId=' + uploadInfo.videoId
            // axios.get(refreshUrl).then(({data}) => {
            //   let uploadAuth = data.UploadAuth
            //   uploader.resumeUploadWithAuth(uploadAuth)
            //   console.log('upload expired and resume upload with uploadauth ' + uploadAuth)
            // })
            // self.statusText = '文件超时...'
          },
          // 全部文件上传结束
          onUploadEnd: function (uploadInfo) {
            console.log("onUploadEnd: uploaded all the files")
            self.statusText = '文件上传完毕'
          }
        })
        return uploader
      }
    }
  }
</script>
<style lang="less">
  .release_content {
    .el-input__inner[type=text] {
      width: 100% !important;
    }

    .el-textarea__inner {
      width: 100%;
      height: 109px;
      resize: none;
    }

    .el-input__inner {
      border-color: #dcdfe6 !important
    }

    .el-upload-dragger:hover {
      border-color: #ff8a00 !important
    }

    .videoType {
      width: 62px;
      height: 39px;
      background-color: #ffffff;
      border-radius: 4px;
      border: solid 1px #eeeeee;
      display: inline-block;
      color: #ff8a00;
      cursor: pointer;
      font-size: 14px;
    }

    .videoType+.videoType {
      margin-left: 24px;
    }

    .activeVideoType {
      color: white;
      background-color: #ff8a00;
    }

    .el-upload,
    .el-upload-dragger {
      width: 100%;
      height: 228px;
    }

    .el-upload__text {
      color: #b8b8b8 !important;

      span {
        color: #ff8a00;
      }
    }

    .realseBtn {
      width: 150px;
      height: 40px;
      background-color: #ff8a00;
      border-radius: 20px;
      color: #ffffff;
      font-size: 16px;
      border: 0px;
      display: block;
      margin: 0px auto
    }

    .icon-shangchuan {
      font-size: 50px;
      color: #b8b8b8 !important;
      display: inline-block;
      margin-top: 30px;
    }

    .submitRelase {
      width: 140px;
      height: 46px;
      background-color: #ff8a00;
      border-radius: 23px;
      color: #ffffff;
      font-size: 18px;
    }

    .submitRelase+.submitRelase{
      margin-left:20px;
    }

    .tipText {
      color: #bbbbbb !important;
      font-size: 14px;

      p {
        line-height: 24px
      }
    }

    .ruleForm {
      width: 80%;
      margin: 0px auto;
    }

    .progress_box {
      width: 100%;
      height: 228px;
      border-radius: 4px;
      border: solid 1px #eeeeee;

      .pro_text {
        text-align: center;
        padding-top: 72px;
        color: #d2d2d2;
        font-size: 14px;
        padding-bottom: 18px;
        line-height: 20px;
      }

      .uploadSuccess_text {
        font-size: 14px;
        color: #ff8a00;
        text-align: center;
        padding-top: 72px;
        padding-bottom: 18px;
        height: 40px;
      }

      span {
        padding-top: 12px;
        color: #adadad;
        font-size: 14px;
        display: block;
        text-align: center;
      }

      .el-progress__text {
        display: none;
      }

      .el-progress-bar__inner {
        background: #ff8a00 !important;
      }

      .el-progress {
        width: 90%;
        margin: 0px auto;
      }
    }
  }
</style>